import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "gatsby";

import "./scss/restaurantsmallcard.scss";

export default function RestaurantSmallCard({
  name,
  slug,
  style,
  description,
  imageSrc,
}) {
  return (
    <div className="restaurant-small-card">
      <Link to={`/restaurants/${slug}`}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <div className="restaurant-info">
              <Typography variant="h6">{name}</Typography>
              <Typography variant="subtitle2" className="restaurant-style">
                {style}
              </Typography>
              <Typography variant="body2">{description}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={imageSrc} alt={name} className="small-thumnail" />
          </Grid>
        </Grid>
      </Link>
    </div>
  );
}

RestaurantSmallCard.propTypes = {
  name: PropTypes.string,
  style: PropTypes.string,
  slug: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
};
