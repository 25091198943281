import React from "react";
import PropTypes from "prop-types";
import { GridList, GridListTile, Typography } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import "./scss/articles.scss";
import ArticleSmallCard from "./ArticleSmallCard";

function Articles({ articles, title, width }) {
  const getGridListCols = () => {
    if (isWidthUp("xl", width)) {
      return 4;
    }

    if (isWidthUp("lg", width)) {
      return 3.25;
    }

    if (isWidthUp("md", width)) {
      return 2.5;
    }

    if (isWidthUp("sm", width)) {
      return 2;
    }

    return 1.25;
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h4" className="title">
        {title}
      </Typography>
      <GridList
        className="article-list"
        cols={getGridListCols()}
        spacing={10}
        cellHeight="auto"
      >
        {articles.map((a, i) => {
          return (
            <GridListTile key={i}>
              <ArticleSmallCard
                key={i}
                title={a.node.title}
                slug={a.node.slug}
                imageSrc={a.node.mainImage.childImageSharp.resize.src}
                restaurantName={a.node.restaurants[0].name}
              />
            </GridListTile>
          );
        })}
      </GridList>
    </React.Fragment>
  );
}

Articles.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.array,
};

export default withWidth()(Articles);
