import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import Restaurants from "../components/Restaurants";
import { Grid } from "@material-ui/core";

import ArticleMainCard from "../components/ArticleMainCard";
import Articles from "../components/Articles";
import Dishes from "../components/Dishes";
import Neighborhoods from "../components/Neighborhoods";
import Regions from "../components/Regions";

const dishNames = [
  "Pho",
  "Pad Thai",
  "Tom Ka Gai",
  "Bulgogi",
  "Kalbi",
  "Beef Stew",
  "Sashimi",
  "Curry",
  "Wonton Noodle Soup",
];

let dishes = [];

for (let i = 0; i < 9; i++) {
  dishes.push({
    imageSrc: `https://loremflickr.com/60${i}/40${i}/asianfood`,
    name: dishNames[i],
  });
}

dishes = dishes.map(d => {
  return {
    node: d,
  };
});

let neighborhoods = [
  "OTR",
  "Central Business District",
  "Mason",
  "West Chester",
  "Norwood",
  "Newport",
];

neighborhoods = neighborhoods.map(n => {
  return {
    node: {
      name: n,
      url: "/neighborhood",
    },
  };
});

let regions = [
  "Central",
  "North",
  "North West",
  "North East",
  "East",
  "West",
  "NKY",
];

regions = regions.map(n => {
  return {
    node: {
      name: n,
      url: "/regions",
    },
  };
});

const IndexPage = () => (
  <Layout>
    <div className="page-container">
      <Grid container>
        <Grid item xs={12}>
          <ArticleMainCard
            imageSrc={`https://loremflickr.com/400/400/tonkotsu-ramen`}
            header="Ramen House"
          >
            <p style={{ textAlign: "center" }}>
              NEW: Check out Ramen House on Dingle St!
            </p>
          </ArticleMainCard>
          {/* <img
          src="https://picsum.photos/seed/picsum/500/500"
          className="main-image"
        /> */}
        </Grid>
      </Grid>
      <StaticQuery
        query={graphql`
          query {
            allStrapiRestaurant {
              edges {
                node {
                  strapiId
                  name
                  slug
                  description
                  cuisines {
                    name
                  }
                  mainImage {
                    absolutePath
                    publicURL
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                }
              }
            }
            allStrapiArticle {
              edges {
                node {
                  date
                  restaurants {
                    name
                  }
                  slug
                  title
                  mainImage {
                    absolutePath
                    publicURL
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <div>
              <Restaurants
                restaurants={data.allStrapiRestaurant.edges}
                title="Hot &amp; New Restaurants"
              />
            </div>
            <div>
              <Articles
                articles={data.allStrapiArticle.edges}
                title="New Articles"
              />
            </div>
            <div>
              <Dishes dishes={dishes} title="Dishes To Try" />
            </div>
            <div>
              <Neighborhoods
                neighborhoods={neighborhoods}
                title="Neighborhoods"
              />
            </div>
            <div>
              <Regions regions={regions} title="Regions" />
            </div>
            <div>
              <Restaurants
                restaurants={[...data.allStrapiRestaurant.edges].reverse()}
                title="Staff Favorites"
              />
            </div>
            <div>
              <Restaurants
                restaurants={data.allStrapiRestaurant.edges}
                title="Random Restaurants"
              />
            </div>
          </div>
        )}
      />
    </div>
  </Layout>
);

export default IndexPage;
