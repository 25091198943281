import React from "react";
import PropTypes from "prop-types";
import RestaurantSmallCard from "./RestaurantSmallCard";
import { GridList, GridListTile, Typography } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import "./scss/restaurants.scss";

function Restaurants({ restaurants, title, width }) {
  const getGridListCols = () => {
    if (isWidthUp("xl", width)) {
      return 4;
    }

    if (isWidthUp("lg", width)) {
      return 3.25;
    }

    if (isWidthUp("md", width)) {
      return 2.5;
    }

    if (isWidthUp("sm", width)) {
      return 2;
    }

    return 1.25;
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h4" className="title">
        {title}
      </Typography>
      <GridList
        className="restaurant-list"
        cols={getGridListCols()}
        spacing={10}
        cellHeight="auto"
      >
        {restaurants.map((r, i) => {
          return (
            <GridListTile key={i}>
              <RestaurantSmallCard
                key={i}
                name={r.node.name}
                style={r.node.cuisines[0].name}
                description={r.node.description}
                slug={r.node.slug}
                imageSrc={r.node.mainImage.childImageSharp.resize.src}
              />
            </GridListTile>
          );
        })}
      </GridList>
    </React.Fragment>
  );
}

Restaurants.propTypes = {
  title: PropTypes.string,
  restaurants: PropTypes.array,
};

export default withWidth()(Restaurants);
